<template>
  <div class="AllTable personTable">
    <div class="buttonTable">
      <el-button @click="distribution">分配</el-button>
      <el-button role="submit" v-permission @click="submit">提交</el-button>
      <el-button role="recall" v-permission @click="cancel">撤回</el-button>
    </div>
    <PocTable
      ref="tTableRef"
      v-loading="loading"
      :data="tableData"
      :current-page.sync="pageConfig.pageNum"
      :total="pageConfig.total"
      size="mini"
      stripe
      :max-height="100000000"
      @select="handleSelectionChange"
      @size-change="pageSizeChange"
      @page-current-change="pageChange"
      @select-all="selectAll"
      highlight-current-row
    >
      <el-table-column type="selection" fixed width="45" />
      <el-table-column
        label="HC ID"
        show-overflow-tooltip
        key="hcId"
        prop="hcId"
        width="150"
      >
        <!--          :sortable="true"-->
        <template slot-scope="scope">
          <span class="textHover" @click="onChat(scope.row)">{{
            scope.row.hcId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="item in columnConfig"
        :key="item.key"
        :prop="item.key"
        :align="item.align"
        :label="item.name"
        :fixed="item.fixed"
        :width="item.key === 'no' ? 60 : null"
        :min-width="item.width"
        show-overflow-tooltip
      ></el-table-column>
      <!--           :sortable="item.sortable"-->
      <el-table-column label="Approval Status" width="150" align="left">
        <template slot-scope="scope">
          <ApprovalStatusDisplay
            :approval-status="scope.row.approvalResultList"
            :unapproved-hc-status="scope.row.unapprovedHcStatus"
          /> </template
      ></el-table-column>
      <el-table-column
        label="CTO驳回理由"
        width="110"
        key="currentApprovalComments"
      >
        <template v-slot="scope">
          <el-tooltip
            v-if="scope.row.ctoApprovalComments"
            popper-class="workorder-reason-popper"
            effect="dark"
            :content="scope.row.ctoApprovalComments"
            placement="top"
            :disabled="isShowTooltip">
            <div
              @mouseover="onMouseOver('ctoApprovalComments'+scope.row.id)"
              style="overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;">
              <span :ref="'ctoApprovalComments'+scope.row.id">{{ scope.row.ctoApprovalComments }}</span>
            </div>
          </el-tooltip>
          <span v-else></span>
        </template>

      </el-table-column>
      <el-table-column
        label="Groupe CFO驳回理由"
        width="110"
        key="currentApprovalComments"
      >
        <template v-slot="scope">
          <el-tooltip
            v-if="scope.row.currentApprovalComments"
            popper-class="workorder-reason-popper"
            effect="dark"
            :content="scope.row.currentApprovalComments"
            placement="top"
            :disabled="isShowTooltip">
            <div
              @mouseover="onMouseOver('approvalComments'+scope.row.id)"
              style="overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;">
              <span :ref="'approvalComments'+scope.row.id">{{ scope.row.currentApprovalComments }}</span>
            </div>
          </el-tooltip>
          <span v-else></span>
        </template>

      </el-table-column>
      <el-table-column
        label="Groupe CFO通过理由"
        width="110"
        key="groupCFOApprovalReason"
      >
        <template v-slot="scope">
          <el-tooltip
            v-if="scope.row.groupCFOApprovalReason"
            popper-class="workorder-reason-popper"
            effect="dark"
            :content="scope.row.groupCFOApprovalReason"
            placement="top"
            :disabled="isShowTooltip">
            <div
              @mouseover="onMouseOver('groupCFOApprovalReason'+scope.row.id)"
              style="overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;">
              <span :ref="'groupCFOApprovalReason'+scope.row.id">{{ scope.row.groupCFOApprovalReason }}</span>
            </div>
          </el-tooltip>
          <span v-else></span>
        </template>

      </el-table-column>
      <el-table-column
        v-for="item in TableColumn1"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :align="item.align"
        :fixed="item.fixed"
        :min-width="item.width"
        show-overflow-tooltip
      ></el-table-column>
      <!--        :sortable="item.sortable"-->
      <el-table-column label="Action" width="130" fixed="right" align="center">
        <template slot-scope="scope">
          <svg-icon
            role="copy"
            v-permission
            icon="tableCopy"
            class="tablePointSvgClass"
            title="复制"
            @click.native="copy(scope.row)"
          />
          <svg-icon
            role="edit"
            v-permission
            icon="tableEdit"
            class="tablePointSvgClass"
            v-if="editRole(scope.row)"
            title="编辑"
            @click.native="onEdit(scope.row)"
          />
          <svg-icon
            role="delete"
            v-permission
            icon="tableDelete"
            class="tablePointSvgClass"
            title="删除"
            v-if="deleteRole(scope.row)"
            @click.native="deleteP(scope.row)"
          />
        </template>
      </el-table-column>
    </PocTable>
    <PersonnelDialogFrom ref="PersonnelDialogFromRef" @onConfirm="onConfirm" />
    <PersonnelSelectionDialog
      ref="PersonnelSelectionDialogRef"
      @onConfirm="personalOnConfirm"
    />
  </div>
</template>

<script>
import { TableColumn } from '@/views/personnelRequisition/TableColumn'
import ApprovalStatusDisplay from '@/views/personnelRequisition/components/ApprovalStatusDisplay.vue'
import PersonnelDialogFrom from '@/views/personnelRequisition/components/PersonnelDialogFrom.vue'
import mixins from '@/views/personnelRequisition/mixins'
import { TableColumn1 } from '@/views/personnelRequisition/TableColumn'

import {
  changeSubmissionStatus,
  changeRecallStatus,
  checkEdit,
  commited,
  deleteForm,
  hrRafFormList,
  savePerson
} from '@/api/personnelRequisition'
import { getItem, setItem } from '@/utils/storage'
import { check, typeRange } from '@/utils/util'

export default {
  mixins: [$PCommon.TableMixin, mixins],
  name: 'AllTable',
  inject: ['parent'],
  components: {
    ApprovalStatusDisplay,
    PersonnelDialogFrom
  },
  data() {
    return {
      columnConfig: TableColumn,
      TableColumn1,
      tableData: [
        // {
        //   hcId: 0,
        //   jobTitle: 'hahahah',
        //   hfmCode: 'fxs121',
        //   assigmentProjectLevel2: 'xxhh1122',
        //   descriptionLevel3: 'sx112221',
        //   annualTotalCostLocalCurrency: 'ex1214444',
        //   hrbpOwner: 'sdsdsdsdsdsd',
        //   approvalStatus: [
        //     '待HRBP提交',
        //     '待HRBP Leader提交',
        //     '待Brand CFO提交审批'
        //   ],
        //   unapprovedHcStatus: '审批中',
        //   hcStatus: 'TBH Unapproved'
        // }
      ],
      loading:false,
      selectVal: [],
      multipleSelection: [],
      isShowTooltip:false,
    }
  },
  methods: {
    onMouseOver(str) {
      // 内容超出，显示文字提示内容
      let tag = this.$refs[str];
      let parentWidth = tag.parentNode.offsetWidth; // 获取元素父级可视宽度
      let contentWidth = tag.offsetWidth; // 获取元素可视宽度
      this.isShowTooltip = contentWidth <= parentWidth;
    },
    onConfirm(val) {
      if (this.formStatus !== 'add' && this.formStatus !== 'copy') {
        const data = {
          id: this.id,
          ...val
        }
        savePerson(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('修改成功')
            this.$emit('close')
            this.$refs.PersonnelDialogFromRef.close()
          }
        })
      } else {
        const data = {
          id: '',
          ...val
        }
        savePerson(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('新增成功')
            this.$emit('close')
            this.$refs.PersonnelDialogFromRef.close()
          }
        })
      }
    },
    selectAll(rows) {
      console.log(rows)
      this.multipleSelection = [];
      this.selectVal = [];
      if (rows.length && rows.length > 0) {
        rows.forEach((row) => {
          if (!this.multipleSelection.find((item) => item === row.id)) {
            this.multipleSelection.push(row.id);
            this.selectVal.push(row)
          }
        });
      } else {
        this.tableData.forEach((row) => {
          this.multipleSelection = this.multipleSelection.filter(
            (item) => item !== row.id
          );
          this.selectVal = this.selectVal.filter(
            (item) => item.id !== row.id
          );
        });
      }
    },

    deleteP(item) {
      const data = {
        ids: item.id.toString()
      }
      this.selectVal = [];
      this.multipleSelection = [];
      deleteForm(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('删除成功')
          this.$emit('close')
        }
      })
    },
    submit() {
      if (this.isSelect()) {
        // setItem('personSelectVal__yc', this.selectVal)
        if (this.submitRole(this.selectVal)) {
          const data = {
            id: this.selectVal.map((item) => item.id).toString(),
            submissionStatus: 'COMMITTED'
          }
          changeSubmissionStatus(data).then((res) => {
            if (res && res.code === 200) {
              this.selectVal = [];
              this.multipleSelection = [];
              this.$message.success('提交成功')
              this.$emit('close')
            }
          })
        } else {
          this.$message.warning('当前所选流程无法提交')
        }
      }
    },
    copy(val) {
      this.formStatus = 'copy';
      this.id = val.id;
      this.selectVal = [];
      this.multipleSelection = [];
      this.$refs.PersonnelDialogFromRef.show({
        status: this.formStatus,
        id: this.id
      })
    },
    cancel() {
      if (this.isSelect()) {
        // setItem('personSelectVal__yc', this.selectVal)
        if (this.cancelRole(this.selectVal)) {
          const data = {
            id: this.selectVal.map((item) => item.id).toString(),
            submissionStatus: 'RECALL'
          }
          changeRecallStatus(data).then((res) => {
            if (res && res.code === 200) {
              this.selectVal = [];
              this.multipleSelection = [];
              this.$message.success('撤回成功')
              this.$emit('close')
            }
          })
        } else {
          this.$message.warning('当前所选流程无法撤回')
        }
      }
    },
    approve() {
      if (this.isSelect()) {
        const data = {
          approvedStatus: 'To Be Approved',
          id: this.selectVal.map((item) => item.id).toString()
        }
        commited(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('审批成功')
            this.$emit('close')
          }
        })
      }
    },
    handleSelectionChange(val,row) {
      console.log('选择', val)
      // this.selectVal = val
      if (this.multipleSelection.find((item) => item === row.id)) {
        //下面这个filter方法就是删除的方法
        this.multipleSelection = this.multipleSelection.filter(
          (item) => item !== row.id
        );
        this.selectVal = this.selectVal.filter(
          (item) => item.id !== row.id
        );
      } else {
        this.multipleSelection.push(row.id);
        this.selectVal.push(row);
      }
    },
    onEdit(val) {
      const data = {
        id: val.id
      }
      this.selectVal = [];
      this.multipleSelection = [];
      checkEdit(data).then((res) => {
        if (res && res.code === 200) {
          this.formStatus = 'edit'
          this.id = val.id
          this.$refs.PersonnelDialogFromRef.show({
            status: this.formStatus,
            id: this.id
          })
        } else {
          this.$message.warning(res.data)
        }
      })
    },
    onChat(val) {
      this.formStatus = 'chat'
      this.id = val.id
      this.$refs.PersonnelDialogFromRef.show({
        status: this.formStatus,
        id: this.id
      })
    },
    highlightedTableData() {
      // let selectVal = getItem('personSelectVal__yc')
      // console.log('缓存的',selectVal)
      // if (selectVal) {
      //   this.tableData.forEach((item) => {
      //     let a = selectVal.find((item1) => item.id === item1.id)
      //     if (a) {
      //       this.$nextTick(() => {
      //         this.$refs.tTableRef.toggleRowSelection(item, true)
      //       })
      //     }
      //   })
      // }
      let selectVal1 = this.multipleSelection
      if (selectVal1) {
        this.tableData.forEach((item) => {
          let a = selectVal1.find((item1) => item.id === item1)
          if (a) {
            this.$nextTick(() => {
              this.$refs.tTableRef.toggleRowSelection(item, true)
            })
          }
        })
      }
    },
    queryApi() {
      const data = {
        ...this.parent.formInline,
        unapprovedHcStatus: this.parent.dropdownValue,
        pageNum: this.pageConfig.pageNum,
        pageSize: this.pageConfig.pageSize
      }
      this.loading = true
      this.tableData = []
      hrRafFormList(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          if (res.data && res.data.list) {
            this.tableData = res.data.list
            this.pageConfig.total = res.data.total
            this.highlightedTableData()
          } else {
            this.tableData = []
            this.pageConfig.total = 0
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.AllTable {
  .textHover {
    &:hover {
      cursor: pointer;
      color: $--color-button-primary;
      text-decoration: underline;
    }
  }
}
</style>
