<template>
  <div class="personnelRequisitionBody">
    <PocCardTable class="personnelRequisition topPocCardTable">
      <template v-slot:headerTitle
      ><span class="titleTop">人员申请</span>
      </template>
      <template v-slot:headerGroup>
        <el-button role="downloadNewHireAndLeaverExcel" v-permission @click="downloadNewHireAndLeaverExcelDialogVisible = true">下载 New hire & Leaver</el-button>
        <el-button role="export" v-permission @click="download">导出全部</el-button>
        <el-button role="create" v-permission @click="create">创建</el-button>
        <el-button type="primary" @click="search">查询</el-button>
      </template>
      <el-form
        :inline="true"
        label-position="top"
        :model="formInline"
        class="personnelRequisitionForm minWidthInput"
      >
        <el-form-item label="HFM Code">
          <el-select
            v-model="formInline.hfmCode"
            clearable
            filterable
            placeholder="请选择"
            @change="changeHFMCode"
          >
            <el-option
              v-for="item in hfmCodeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Level 2">
          <el-select
            v-model="formInline.level2"
            clearable
            filterable
            placeholder="请选择"
            @change="changeLevel2"
          >
            <el-option
              v-for="item in level2List"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Level 3">
          <el-select
            v-model="formInline.level3"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in level3List"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="HC Nature">
          <el-select
            v-model="formInline.hcNature"
            clearable
            filterable
            placeholder="HC Nature"
          >
            <el-option
              v-for="item in hcNatureList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="HC Sub Nature">
          <el-select
            v-model="formInline.hcSubNature"
            clearable
            filterable
            placeholder="HC Sub Nature"
          >
            <el-option
              v-for="item in hcSubNatureList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="HRBP Owner">
          <el-select
            v-model="formInline.hrbpOwner"
            clearable
            filterable
            placeholder="HRBP Owner"
          >
            <el-option
              v-for="item in hrbpOwnerList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </PocCardTable>
    <PocCardTable class="content-Body">
      <el-dropdown
        class="content-Body-dropdown"
        @command="dropdownCommand"
        trigger="click"
      >
        <span class="el-dropdown-link">
          <svg-icon icon="screen" style="margin-right: 4px; font-size: 16px" />
          <span style="font-size: 12px">{{ dropdownText }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="dropdownObj.All.value" :class="{'isCmd':dropdownValue===dropdownObj.All.value}">{{
              dropdownObj.All.label
            }}</el-dropdown-item>
          <el-dropdown-item :command="dropdownObj.MyToDo.value" :class="{'isCmd':dropdownValue===dropdownObj.MyToDo.value}">{{
              dropdownObj.MyToDo.label
            }}</el-dropdown-item>
          <el-dropdown-item :command="dropdownObj.submitted.value" :class="{'isCmd':dropdownValue===dropdownObj.submitted.value}">{{
              dropdownObj.submitted.label
            }}</el-dropdown-item>
          <el-dropdown-item :command="dropdownObj.underApproval.value" :class="{'isCmd':dropdownValue===dropdownObj.underApproval.value}">
            <el-dropdown placement='right-start' @command="dropdownItemCommand">
              <span class="el-dropdown-link" style="font-size: 12px">
                {{ dropdownObj.underApproval.label}}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <template v-for='(items, index) in dropdownObj.underApproval.childer' >
                  <el-dropdown-item :command="items.value" :class="{'isCmd':dropdownValue===dropdownObj.underApproval.value && approvalStatus === items.value}" :key="index"
                  >{{items.label}}</el-dropdown-item>
                </template>
              </el-dropdown-menu>
            </el-dropdown>
          </el-dropdown-item>
          <el-dropdown-item :command="dropdownObj.deleted.value" :class="{'isCmd':dropdownValue===dropdownObj.deleted.value}">{{
              dropdownObj.deleted.label
            }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button
        class="content-Body-button"
        role="exportSelect"
        v-permission
        @click="exportSelect"
        v-loading.fullscreen.lock="fullscreenLoading"
      >导出选中</el-button>
      <component
        ref="personalComponentRef"
        @close="search"
        :is="componentSync"
      ></component>
    </PocCardTable>
    <PersonnelDialogFrom ref="PersonnelDialogFromRef" :statusCode="'1'" @onConfirm="onConfirm" />
    <el-dialog
      title="下载 New Hire 及 Leaver"
      :visible.sync="downloadNewHireAndLeaverExcelDialogVisible"
      width="30%"
    >
      <el-date-picker
        v-model="downloadNewHireAndLeaverExcelDaterange"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="downloadNewHireAndLeaverExcelDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="downloadNewHireAndLeaverExcel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { exportDownload } from "@/api/export";
import PersonnelDialogFrom from '@/views/personnelRequisition/components/PersonnelDialogFrom'
import AllTable from '@/views/personnelRequisition/components/AllTable.vue'
import submittedTable from '@/views/personnelRequisition/components/submittedTable.vue'
import underApprovalTable from '@/views/personnelRequisition/components/underApprovalTable.vue'
import deletedTable from '@/views/personnelRequisition/components/deletedTable.vue'
import MyToDo from "@/views/personnelRequisition/components/MyToDo";
import {
  deleteForm,
  exportPersone,
  getHRBPOwnerList,
  hrRafFormList,
  getEntityHfmCode,
  getLevel2,
  getDescriptionLevel3,
  savePerson,
  downloadSelect, downloadAll
} from '@/api/personnelRequisition'
import Position from '@/views/intern/pages/components/Position.vue'
import { removeItem, setItem } from '@/utils/storage'
import { downLoadFileByBlob } from "@/utils/util";
export default {
  name: 'personnelRequisition',
  mixins: [$PCommon.TableMixin],
  components: {
    Position,
    PersonnelDialogFrom,
    AllTable,
    submittedTable,
    underApprovalTable,
    MyToDo,
    deletedTable
  },
  data() {
    return {
      downloadNewHireAndLeaverExcelDialogVisible: false,
      downloadNewHireAndLeaverExcelDaterange: '',
      loading: false,
      dropdownText: '我的待办',
      dropdownValue: 'MYTODO',
      componentSync: 'MyToDo',
      fullscreenLoading: false,
      dropdownObj: {
        All: {
          label: 'All',
          value: 'ALL',
          component: 'AllTable'
        },
        MyToDo: {
          label: '我的待办',
          value: 'MYTODO',
          component: 'MyToDo'
        },
        submitted: {
          label: '待提交',
          value: 'TOBESUBMITTED',
          component: 'submittedTable'
        },
        underApproval: {
          label: '审批中',
          value: 'UNDERAPPROVAL',
          component: 'underApprovalTable',
          childer:[
            {
              label: 'HRBP已提交',
              value: '1',
              component: 'underApprovalTable',
            },
            {
              label: 'HRBP Leader已提交',
              value: '2',
              component: 'underApprovalTable',
            },
            {
              label: 'Finance BP已提交',
              value: '3',
              component: 'underApprovalTable',
            },
            {
              label: 'Brand CFO已提交',
              value: '4',
              component: 'underApprovalTable',
            },
            {
              label: 'CTO已提交',
              value: '5',
              component: 'underApprovalTable',
            },
            // {
            //   label: 'Group CFO已提交',
            //   value: '6',
            //   component: 'underApprovalTable',
            // },
          ]
        },
        deleted: {
          label: '已删除',
          value: 'DELETED',
          component: 'deletedTable'
        }
      },
      formInline: {
        hcNature: '',
        level2: '',
        level3: '',
        hcSubNature: '',
        hrbpOwner: ''
      },
      hcNatureList: [
        {
          label: 'New Hire',
          value: 'New Hire'
        },
        {
          label: 'Replacement',
          value: 'Replacement'
        },
        {
          label: 'Transfer in',
          value: 'Transfer in'
        },
        {
          label: 'Transfer out with HC',
          value: 'Transfer out with HC'
        },
        {
          label: 'HC Delete',
          value: 'HC Delete'
        }
      ],
      hcSubNatureList: [
        {
          label: 'New Hire',
          value: 'New Hire'
        },
        {
          label: 'HC Delete',
          value: 'HC Delete'
        },
        {
          label: 'Transfer out without HC',
          value: 'Transfer out without HC'
        },
        {
          label: 'Transfer out with HC',
          value: 'Transfer out with HC'
        },
        {
          label: 'Leaver',
          value: 'Leaver'
        },
        {
          label: 'Transfer in with HC',
          value: 'Transfer in with HC'
        },
        {
          label: 'Transfer in without HC',
          value: 'Transfer in without HC'
        }
      ],
      hrbpOwnerList: [],
      formStatus: '',
      level2List:[],
      level3List:[],
      hfmCodeList:[],
      approvalStatus:'',
    }
  },
  provide() {
    return {
      parent: this
    }
  },
  beforeRouteLeave(to, from, next) {
    removeItem('personSelectVal__yc')
    next()
  },
  created() {
    this.getFormList()
  },
  mounted() {
    this.search()
  },
  methods: {
    changeHFMCode(val){
      if(val){
        getLevel2({hfmCode:val}).then(({code,data})=>{
          if(code === 200){
            this.level2List = data
            this.formInline.level2 = ''
          }
        })
      }else {
        getLevel2().then(({code,data})=>{
          if(code === 200){
            this.level2List = data
          }
        })
      }
    },
    changeLevel2(val){
      if(val){
        getDescriptionLevel3({assigmentProjectLevel2:val}).then(({code,data})=>{
          if(code === 200){
            this.level3List = data
            this.formInline.level3 = ''
          }
        })
      }else {
        getDescriptionLevel3().then(({code,data})=>{
          if(code === 200){
            this.level3List = data
          }
        })
      }
    },
    exportSelect(){
      let multipleSelection = this.$refs.personalComponentRef.multipleSelection
      if(multipleSelection.length === 0){
        this.$message.error('请选择数据导出');
        return
      }
      this.fullscreenLoading = true
      downloadSelect(multipleSelection).then(res=>{
        this.fullscreenLoading = false
        if(res){
          downLoadFileByBlob(res)
        }
      })
    },
    dropdownCommand(val) {
      Object.keys(this.dropdownObj).forEach((item) => {
        if (this.dropdownObj[item].value === val) {
          this.dropdownText = this.dropdownObj[item].label
          this.dropdownValue = this.dropdownObj[item].value
          this.componentSync = this.dropdownObj[item].component
        }
      })
      this.approvalStatus = ''
      this.$nextTick(() => {
        this.search()
      })
    },
    dropdownItemCommand(val) {
      this.dropdownText = '审批中'
      this.dropdownValue = 'UNDERAPPROVAL'
      this.componentSync = 'underApprovalTable'
      this.approvalStatus = val
      this.$nextTick(() => {
        this.search()
      })
    },
    create() {
      this.formStatus = 'add'
      this.id = ''
      this.$refs.PersonnelDialogFromRef.show({
        status: this.formStatus,
        titlePerson: '用人申请单'
      })
    },
    getFormList() {
      getHRBPOwnerList().then((res) => {
        if (res && res.code === 200) {
          this.hrbpOwnerList = res.data
        }
      })
      getEntityHfmCode().then(({code,data})=>{
        if(code === 200){
          this.hfmCodeList = data
        }
      })
      getLevel2().then(({code,data})=>{
        if(code === 200){
          this.level2List = data
        }
      })
      getDescriptionLevel3().then(({code,data})=>{
        if(code === 200){
          this.level3List = data
        }
      })
    },
    download() {
      // const data = {
      //   ...this.formInline,
      //   unapprovedHcStatus: this.dropdownValue
      // }
      downloadAll()
    },
    /**
     * 下载 New hire & Leaver
     */
     downloadNewHireAndLeaverExcel() {
      exportDownload('staff/downLoad/newHireAndLeaver', {
        startDay: this.$dayjs(this.downloadNewHireAndLeaverExcelDaterange[0]).format('YYYY-MM-DD'),
        endDay: this.$dayjs(this.downloadNewHireAndLeaverExcelDaterange[1]).format('YYYY-MM-DD'),
      })
      this.downloadNewHireAndLeaverExcelDialogVisible = false
     },
    search() {
      const data = {
        ...this.formInline,
        unapprovedHcStatus: this.dropdownValue,
        approvalStatus:this.approvalStatus
      }
      this.$refs.personalComponentRef.pageConfig.pageNum = 1
      this.$refs.personalComponentRef.multipleSelection = []
      this.$refs.personalComponentRef.pageConfig.pageNum = 1
      this.$refs.personalComponentRef.queryApi(data)
    },
    onConfirm(val) {
      if (this.formStatus !== 'add' && this.formStatus !== 'copy') {
        const data = {
          id: this.id,
          ...val
        }
        savePerson(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('修改成功')
            this.search()
            this.$refs.PersonnelDialogFromRef.close()
          }
        })
      } else {
        const data = {
          id: '',
          ...val
        }
        savePerson(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('新增成功')
            this.search()
            this.$refs.PersonnelDialogFromRef.close()
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.personnelRequisitionBody {
  overflow-x: hidden;

  :deep(.titleTop) {
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #303133;
  }
  .content-Body {
    position: relative;
    :deep(.el-card__body) {
      padding: 12px 20px 0 20px !important;
      .content-Body-dropdown {
        //margin-bottom: 12px;
        box-sizing: border-box;
        position: absolute;
        top: 22px;
        color: $--color-button-primary;
        cursor: pointer;
        font-size: 12px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
      }
      .content-Body-button{
        position: absolute;
        top: 12px;
        right: 10px;
      }
      .personTable {
        margin-top: 40px;
        .buttonTable {
          position: absolute;

          top: 12px;
          left: 84px;
          .el-button {
            width: 68px;
            height: 30px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
          }
        }
      }
    }
  }

  .el-form {
    .el-form-item {
      margin-bottom: 0 !important;
    }
  }
  .personnelRequisition {
    .topTitle {
      display: flex;
      justify-content: space-between;
    }
  }
}
:deep(.isCmd){
  background-color: #f8f5f0;
  color: #c8ac84;
}
</style>
