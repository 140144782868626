let requiredArr = [
  'countryId',
  'hfmCode',
  'businessUnitId',
  'internalBrandNamelevel',
  'internalBrandId',
  // 'client',
  'ceoName1',
  'laborCodeId',
  'departmentId',
  'jobTitle',
  'estimatedStartDate',
  'workSchedulesId',
  'thirteenSalary',
  'baseSalary',
  'annualTotalCost',
  'euroConv',
  'legalEntity',
  'annualCompensation',
  'businessCaseToJustifyTheRecruitment',
  'benefits',
  'lineManagerEmail',
  'type1Id',
  'assigmentProjectLevel2',
  'descriptionLevel3',
  'workingLocationId',
  'ceo',
]
//  'benefits',
let messageErr = {
  countryId: 'Country',
  hfmCode: 'HFM Code',
  businessUnitId: 'Bussiness Unit Name',
  internalBrandId: 'Internal Brand Name-level 2',
  // client: 'Client',
  ceoName1: 'CEO-1',
  laborCodeId: 'Labor Code',
  departmentId: 'Department',
  jobTitle: 'Job Title',
  estimatedStartDate: 'Estimated Start Date',
  workSchedulesId: 'Work Schedules',
  thirteenSalary: '13th Salary',
  baseSalary: 'Base Salary (Local Currency)',
  annualTotalCost: 'Annual Total Cost (Local Currency)',
  euroConv: 'Euro Conv',
  annualCompensation: 'Annual Compensation (euros)',
  businessCaseToJustifyTheRecruitment:
    'Business Case to Justify the Recruitment',
  legalEntity: 'Legal Entity',
  benefits: 'Benefits (Local Currency)',
  lineManagerEmail: 'Line Manager Email',
  type1Id: 'type1',
  assigmentProjectLevel2: 'Assigment/project (Level2)',
  descriptionLevel3: 'Description(Level3)',
  workingLocationId: 'Work location',
  ceo: 'CEO',
}
export const rules = {}
requiredArr.forEach((item) => {
  rules[item] = [{ required: true, message: `${messageErr[item]} is required` }]
})
