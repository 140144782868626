<template>
  <div
    class="PersonnelForm"
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
  >
    <PGTitle
      v-if="position === 'mergeHC'"
      class="mergeHcSlot"
      title="HC合并信息"
    >
      <div class="mergeHcSlot__content">
        <div class="mergeHcSlot__content__one">
          <el-statistic
            group-separator=","
            :precision="2"
            :value="annualTotalCost"
          ></el-statistic>
          <div class="hcPlus" style="width: 200px">
            <div style="margin-bottom: 8px">
              {{ hcPlus }}
            </div>
            <div>Annual Total Cost (Local Currency)</div>
          </div>
        </div>
        <div class="mergeHcSlot__content__two">
          <charts
            :data-source="dataSource"
            v-if="chartStatus"
            :key="formKeyHc"
            ref="charsRef"
            @hcClick="hcClick"
          />
        </div>
      </div>
    </PGTitle>
    <el-form
      :inline="true"
      ref="personFormPersonRef"
      :model="formInline"
      :disabled="isDisabled"
      :rules="rules"
      :key="formKey"
      label-position="top"
      :show-message="isShowmessage"
      :class="['PersonnelDialogFrom-inline', isShowmessage ? '': 'hide-error-border']"
    >
      <div class="title">职位基础信息</div>
      <el-row :gutter="24">
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Country" prop="countryId">
              <el-select
                v-model="formInline.countryId"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in countryList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="HFM Code" prop="hfmCode">
              <el-select
                v-model="formInline.hfmCode"
                clearable
                filterable
                placeholder="请选择"
                @change="changeHfmCode"
              >
                <el-option
                  v-for="item in hfmCodeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item
              label="Assigment/project（Level2）"
              prop="assigmentProjectLevel2"
            >
              <el-select
                v-model="formInline.assigmentProjectLevel2"
                clearable
                filterable
                @change="assigmentProjectLevel2Change"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in assigmentProjectLevel2List"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.enable === false"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col>

        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item
              label="Description（Level3）"
              prop="descriptionLevel3"
            >
              <el-select
                v-model="formInline.descriptionLevel3"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in descriptionLevel3List"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.enable === false"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col>
        <!-- <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Labor Code" prop="laborCodeId">
              <el-select
                v-model="formInline.laborCodeId"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in laborCodeIdList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col> -->
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Bussiness Unit Name" prop="businessUnitId">
              <!-- <el-input
                v-model="formInline.businessUnitName"
                disabled
              ></el-input> -->
              <el-select
                v-model="formInline.businessUnitId"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in businessUnitList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Legal Entity" prop="legalEntity">
              <el-select
                v-model="formInline.legalEntity"
                clearable
                filterable
                @change="getSocialSecurity('legal','')"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in legalEntityList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Department" prop="departmentId">
              <el-select
                v-model="formInline.departmentId"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in departmentIdList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Client" prop="client">
              <!-- <el-input v-model="formInline.client"></el-input> -->
              <el-select
                v-model="formInline.client"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in clientList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col>
      </el-row>
      <el-row :gutter="24">
        <!--                  @click.native="openClient"-->
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Job Title" prop="jobTitle">
              <el-input
                v-model="formInline.jobTitle"
                clearable
                filterable
                placeholder="Job Title"
              ></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item
              label="Estimated Start Date"
              prop="estimatedStartDate"
            >
              <el-date-picker
                v-model="formInline.estimatedStartDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="Estimated Start Date"
              >
              </el-date-picker>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Work location" prop="workingLocationId">
              <el-select
                v-model="formInline.workingLocationId"
                clearable
                filterable
                @change="getSocialSecurity"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in workingLocationIdList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="CEO" prop="ceo">
              <el-input
                v-model="formInline.ceoName"
                placeholder="请输入"
                @click.native="selectPerson('CEO','ceoName','ceo')"
                suffix-icon="el-icon-search"
                readonly
              ></el-input>
            </el-form-item></div
        ></el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-form-item label="CEO-1" prop="ceoName1">
              <el-input
                v-model="formInline.ceoName1"
                placeholder="CEO-1"
                @click.native="selectPerson('induction', 'ceoName1', 'ceo1')"
                suffix-icon="el-icon-search"
                readonly
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="6"
        ><div class="grid-content bg-purple">
          <el-form-item label="Name of LM" prop="lineManagerName">
            <el-input
              v-model="formInline.lineManagerName"
              placeholder="Name of LM"
              @click.native="selectPerson('induction')"
              suffix-icon="el-icon-search"
              readonly
            ></el-input>
          </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Lion ID of LM">
              <el-input
                v-model="formInline.lineManagerLiid"
                placeholder="Lion ID of LM"
                @click.native="selectPerson('induction')"
                suffix-icon="el-icon-search"
                readonly
              ></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6">
          <el-form-item label="LM Email" prop="lineManagerEmail">
            <el-input
              v-model="formInline.lineManagerEmail"
              placeholder="LM Email"
              @click.native="selectPerson('induction')"
              suffix-icon="el-icon-search"
              readonly
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item
              label="Internal Candidate or New Hire?"
              prop="internalCandidateOrNewHireId"
            >
              <el-select
                v-model="formInline.internalCandidateOrNewHireId"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in internalCandidateOrNewHireIdList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Candidate Name" prop="newCandidateName">
              <el-input
                v-model="formInline.newCandidateName"
                placeholder="Candidate Name"
              ></el-input>
            </el-form-item></div
        ></el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label=".">
            <el-checkbox v-model="formInline.inFinalRecruitmentStage"
              >In Final Recruitment Stage</el-checkbox
            >
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label=".">
            <el-checkbox
              v-model="formInline.openLessThan90daysOrFreelanceUtillized"
              >Open less than 90 Days or Freelance Utillized</el-checkbox
            >
          </el-form-item>
        </el-col>
      </el-row>
      <div class="title">工作制&薪水</div>
      <el-row :gutter="24">
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Work Schedules" prop="workSchedulesId">
              <el-select
                v-model="formInline.workSchedulesId"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in workSchedulesIdList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col>
        <el-col :span="6" class="thirteenSalary">
          <div class="grid-content bg-purple thirteenSalary thirteenSalarys">
            <el-form-item
              class="thirteenSalary"
              label="13th Salary"
              prop="thirteenSalary"
            >
              <el-switch
                v-model="formInline.thirteenSalary"
                :disabled="position === 'hcPerson'"
                active-color="#6EBAAF"
                inactive-color="#E36124"
              >
              </el-switch>
              <!--              <el-select-->
              <!--                v-model="formInline.thirteenSalary"-->
              <!--                clearable-->
              <!--                filterable-->
              <!--                placeholder="请选择"-->
              <!--              >-->
              <!--                <el-option-->
              <!--                  v-for="item in thirteenSalaryList"-->
              <!--                  :key="item.value"-->
              <!--                  :label="item.label"-->
              <!--                  :value="item.value"-->
              <!--                />-->
              <!--              </el-select>-->
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item
              label="Benefits (Local Currency)"
              :prop="!isBenefits ? 'benefits' : null"
            >
              <el-input
                v-model="formInline.benefits"
                @input="benefitsChange"
                :disabled="true"
              />
            </el-form-item></div
        ></el-col>
        <el-col :span="6" class="flexFormItem">
          <el-form-item
            label="Relocation Cost"
            prop="baseSalary"
            style="margin-right: 0 !important"
          >
            <el-input-number
              v-model="formInline.relocationCost"
              :disabled="position === 'hcPerson'"
              @change="handleChangeRelocationCost"
            ></el-input-number>
          </el-form-item>
          <!--                    @keyup.native="handleChangeRelocationCost($event)"-->
          <div class="yearly"><span>Yearly</span></div>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="Other benefits" prop="otherBenefits">
            <el-input-number
              v-model="formInline.otherBenefits"
              :disabled="position === 'hcPerson'"
              @change="handleChangeOtherBenefits"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="五险一金" prop="fiveInsurancesAndHousingFund">
            <el-input-number
              :min="0"
              v-model="formInline.fiveInsurancesAndHousingFund"
              :disabled="position === 'hcPerson'"
              @change="handleChangefiveInsurancesAndHousingFund"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-form-item
              label="Base Salary (Local Currency)"
              class="BaseSalary"
              prop="baseSalary"
            >
              <BaseSalary
                v-model="formInline.baseSalary"
                :position="position"
                :thirteenSalary="formInline.thirteenSalary"
                @MonthlyChange="MonthlyChange"
              />
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8" class="flexFormItem">
          <el-form-item
            label="Annual Compensation (euros)"
            class="Relocation"
            prop="annualCompensation"
          >
            <el-input-number
              v-model="formInline.annualCompensation"
              disabled
              @change="handleChangeAnnualCompensation"
            ></el-input-number>
            <div class="yearly"><span>Yearly</span></div>
          </el-form-item>
        </el-col>
        <el-col :span="8" class="flexFormItem">
          <el-form-item
            label="Annual Total Cost (Local Currency)"
            class="Relocation totalCost"
            prop="annualTotalCost"
          >
            <el-input-number
              v-model="formInline.annualTotalCost"
              disabled
              @change="handleChangeAnnualTotalCost"
            ></el-input-number>
            <div class="yearly"><span>Yearly</span></div>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Euro Conv" class="Relocation" prop="euroConv">
            <el-input-number
              v-model="formInline.euroConv"
              :disabled="position === 'hcPerson'"
              @change="handleChangeEuroConv"
              :min="0"
            ></el-input-number> </el-form-item
        ></el-col>
        <!--                   @keyup.native="handleChangeEuroConv($event)"-->
      </el-row>
      <el-row :gutter="24" class="Billable">
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-checkbox
              v-model="formInline.bill100Percent"
              :disabled="position === 'hcPerson'"
              >100%Billable</el-checkbox
            >
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-checkbox
              v-model="formInline.budgetWithinLastRf"
              :disabled="position === 'hcPerson'"
              >Budget within latest RF (Y/N)</el-checkbox
            >
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-checkbox
              v-model="formInline.hireDoesNotIncreaseCapacity"
              :disabled="position === 'hcPerson'"
              >Hire Does Not Increase Capacity</el-checkbox
            >
          </div></el-col
        >
        <el-col :span="6">
          <div class="LTIM">
            <el-checkbox
              v-model="formInline.isEligibleForLTI"
              :disabled="position === 'hcPerson'"
              >Is Eligible for LTI</el-checkbox
            >
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="">
            <el-tooltip
              class="item"
              effect="dark"
              content="Minimum Guaranteed Bonus Amount"
              placement="top"
            >
              <div class="itemEllipsis">Minimum Guaranteed Bonus Amount</div>
            </el-tooltip>
            <el-input-number
              style="width: 100%"
              v-model="formInline.minimumGuaranteedBonusAmount"
              :disabled="position === 'hcPerson'"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="">
            <el-tooltip
              class="item"
              effect="dark"
              content="Minimum Guaranteed Bonus Currency"
              placement="top"
            >
              <div class="itemEllipsis">Minimum Guaranteed Bonus Currency</div>
            </el-tooltip>
            <el-input
              v-model="formInline.minimumGuaranteedBonusCurrency"
              :disabled="position === 'hcPerson'"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="8">
          <el-form-item label="">
            <el-tooltip
              class="item"
              effect="dark"
              content="Minimum Guaranteed Bonus End Date"
              placement="top"
            >
              <div class="itemEllipsis">Minimum Guaranteed Bonus End Date</div>
            </el-tooltip>
            <el-date-picker
              v-model="formInline.minimumGuaranteedBonusEndDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="Minimum Guaranteed Bonus End Date"
              :disabled="position === 'hcPerson'"
            /> </el-form-item
        ></el-col>
      </el-row>
      <div class="title">fillBack信息</div>
      <el-row :gutter="24">
        <el-col :span="6"
          ><div class="grid-content bg-purple typeSelect">
            <el-form-item label="Type1" prop="type1Id">
              <el-select
                v-model="formInline.type1Id"
                clearable
                filterable
                popper-class="typeSelect"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in type1IdList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <span class="selectLabel">{{ item.label }}</span>
                  <span class="selectMsg">{{ item.msg }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="" prop="ifBackfillNameOfDepartingEmployee">
              <el-tooltip
                class="item"
                effect="dark"
                content="If Backfill,name of departing employee"
                placement="top"
              >
                <div class="itemEllipsis">
                  If Backfill,name of departing employee
                </div>
              </el-tooltip>
              <el-input
                v-model="formInline.ifBackfillNameOfDepartingEmployee"
                placeholder="If Backfill,name of departing employee"
              ></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item
              label="If Backfill,date of departure"
              prop="ifBackfillDateOfDeparture"
            >
              <el-date-picker
                v-model="formInline.ifBackfillDateOfDeparture"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="If Backfill,date of departure"
              >
              </el-date-picker>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item
              label="If Backfill,where did employee go"
              prop="ifBackfillNameOfDepartingEmployee"
            >
              <el-input
                v-model="formInline.ifBackfillWhereDidEmployeeGo"
                placeholder="If Backfill,where did employee go"
              ></el-input>
            </el-form-item></div
        ></el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12"
          ><div class="grid-content bg-purple RelocationCost">
            <el-form-item
              label=""
              prop="ifBackfillDepartingEmployeeAnnualTotalCost"
            >
              <el-tooltip
                class="item"
                effect="dark"
                content="If Backfill,Departing Employee Annual Total Cost (Local Currency)"
                placement="top"
              >
                <div class="itemEllipsis">
                  If Backfill,Departing Employee Annual Total Cost (Local
                  Currency)
                </div>
              </el-tooltip>
              <el-input-number
                v-model="formInline.ifBackfillDepartingEmployeeAnnualTotalCost"
                :min="0"
              ></el-input-number>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="12"
          ><div class="grid-content bg-purple RelocationCost">
            <el-form-item
              label=""
              prop="ifBackfillDepartingEmplyeeAnnualBaseSalary"
            >
              <el-tooltip
                class="item"
                effect="dark"
                content="If backfill,departing Employee Annual Base Salary (Local Currency)"
                placement="top"
              >
                <div class="itemEllipsis">
                  If backfill,departing Employee Annual Base Salary (Local
                  Currency)
                </div>
              </el-tooltip>
              <el-input-number
                v-model="formInline.ifBackfillDepartingEmplyeeAnnualBaseSalary"
                :min="0"
              ></el-input-number>
            </el-form-item></div
        ></el-col>
      </el-row>
      <div class="title">其它</div>
      <el-row :gutter="24">
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <el-form-item label="Event Reason Name" prop="eventReasonName">
              <el-select
                v-model="formInline.eventReasonName"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in eventReasonNameList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col>
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <el-form-item label="Cost Center" prop="costCenter">
              <el-select
                v-model="formInline.costCenter"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in costCenterList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col>
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <el-form-item label="Job Classification" prop="jobClassification">
              <el-select
                v-model="formInline.jobClassification"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in jobClassificationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24" style="height: 100px"
          ><div class="grid-content bg-purple">
            <el-form-item
              label="Business Case to Justify the Recruitment"
              placeholder="Business Case to Justify the Recruitment"
              prop="businessCaseToJustifyTheRecruitment"
            >
              <el-input
                type="textarea"
                placeholder="请输入内容"
                maxlength="4000"
                show-word-limit
                v-model="formInline.businessCaseToJustifyTheRecruitment"
              >
              </el-input>
            </el-form-item></div
        ></el-col>
      </el-row>
    </el-form>
    <el-form
      :inline="true"
      ref="personFormRef"
      :model="additionalInformation"
      :rules="rules"
      label-position="top"
      class="PersonnelDialogFrom-inline"
    >
      <div v-if="position === 'hcPerson'">
        <div class="title">补充信息</div>
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item
              label="Parent Function"
              placeholder="Parent Function"
              prop="parentFunction"
            >
              <el-select
                clearable
                v-model="additionalInformation.parentFunction"
                @change="parentFunctionChange"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in parentFunctionList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="Child Function"
              placeholder="Child Function"
              prop="childFunction"
            >
              <el-select
                clearable
                v-model="additionalInformation.childFunction"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in childFunctionList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="Relationship Effective  Start Day"
              placeholder="Relationship Effective  Start Day"
              prop="relationshipEffectiveStartDay"
            >
              <el-input
                v-model="additionalInformation.relationshipEffectiveStartDay"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="Relationship Type"
              placeholder="Relationship Type"
              prop="relationshipType"
            >
              <el-input
                v-model="additionalInformation.relationshipType"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <clientDialog ref="clientDialogRef" @onConfirm="clientDialogRefConfirm" />
    <!-- :hfmCode="this.selectEditMsg.hfmCode" -->
    <personnelSelectionDialog
      ref="personnelSelectionDialogRef"
      @onConfirm="onPersonConfirm"
    />
  </div>
</template>

<script>
import BaseSalary from '@/views/personnelRequisition/components/BaseSalary'
import charts from '@/views/quotaManagement/components/charts.vue'
import PGTitle from '@/components/PG-title/PG-title.vue'
import personnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog'
import {
  getBusinessUnitList,
  getDescriptionLevel3,
  getCodeValue,
  getCountry,
  getEntityHfmCode,
  getEuroConv,
  getFormData,
  getAssigmentProjectLevel2,
  getLevel2,
  getLevel3,
  getBuNameByHfmCode,
  getLegalEntityList,
  getDepartmentList,
  getSocialSecurityBase,
  getWorkLocationList,
  getClient,
  getClient1,
  getHcInformationByHcId,
  getMergeData,
  getNewHc
} from '@/api/personnelRequisition'
import { check, throttle, typeRange } from '@/utils/util'
import { rules } from '@/views/personnelRequisition/rules'
import { resetFormInline } from '@/views/personnelRequisition/resetForm'
import clientDialog from '@/views/intern/dialog/clientDialog'
import { getChildFunction, getLineManager, getParentFunction, reject } from '@/api/quto'
import { checkOrganization, getSysOrgOrganizations } from "@/api/organization";
export default {
  name: 'PersonnelForm',
  components: {
    BaseSalary,
    clientDialog,
    PGTitle,
    charts,
    personnelSelectionDialog
  },
  data() {
    return {
      clientList: [],
      drawer: false,
      direction: 'btt',
      annualTotalCost: 0,
      formKey: Math.random() + 'ss',
      additionalInformation: {
        childFunction: '',
        parentFunction: '',
        relationshipEffectiveStartDay: '',
        relationshipType: ''
      },
      formInline: {
        countryId: '156',
        hfmCode: '',
        fiveInsurancesAndHousingFund: 0,
        monthly: '',
        otherBenefits: 1100,
        // businessUnitName: '',
        businessUnitId: '',
        assigmentProjectLevel2: '',
        lineManagerLiid: '',
        lineManagerEmail: '',
        lineManagerName: '',
        descriptionLevel3: '',
        laborCodeId: '',
        departmentId: '',
        legalEntity: '',
        jobTitle: '',
        workingLocationId: '',
        estimatedStartDate: '',

        newCandidateName: '',
        internalCandidateOrNewHireId: '',

        openLessThan90daysOrFreelanceUtillized: false,
        baseSalary: 0,
        relocationCost: 0,

        annualTotalCost: 0,
        euroConv: 0,
        client: '',
        annualCompensation: 0,
        bill100Percent: false,
        budgetWithinLastRf: false,
        inFinalRecruitmentStage: false,
        type1Id: '',
        ifBackfillNameOfDepartingEmployee: '',
        ifBackfillDateOfDeparture: '',
        ifBackfillWhereDidEmployeeGo: '',
        ifBackfillDepartingEmployeeAnnualTotalCost: '',
        ifBackfillDepartingEmplyeeAnnualBaseSalary: '',
        workSchedulesId: '',
        benefits: '',
        eventReasonName: '',
        costCenter: '',
        jobClassification: '',
        businessCaseToJustifyTheRecruitment: '',
        thirteenSalary: false,
        minimumGuaranteedBonusAmount: '',
        minimumGuaranteedBonusCurrency: 'CNY',
        minimumGuaranteedBonusEndDate: '',
        isEligibleForLTI: '',
        newHcType: '',
        newHcId: '',
        ceoName: '',
        ceo: '',
        ceoName1: '',
        ceo1: '',
        ceoType1: '',
      },
      hcSubmitStatus: true,
      // titlePerson: '用人申请单',
      dataSource: {},
      loadingStatus: false,
      loading: false,
      countryList: [],
      chartStatus: true,
      hfmCodeList: [],
      businessUnitList: [],
      assigmentProjectLevel2List: [],
      descriptionLevel3List: [],
      laborCodeIdList: [],
      departmentIdList: [],
      workingLocationIdList: [],
      internalCandidateOrNewHireIdList: [],
      type1IdList: [],
      mergeHcCount: 30000000,
      workSchedulesIdList: [],
      eventReasonNameList: [],
      costCenterList: [],
      legalEntityList: [],
      jobClassificationList: [],
      parentFunctionList: [],
      childFunctionList: [],
      clientList: [],
      // position: '',
      // selectVal: [],
      SocialSecurity: 0,
      thirteenSalaryList: [
        {
          label: '是',
          value: true
        },
        {
          label: '否',
          value: false
        }
      ],
      formKeyHc: Math.random(),
      ApiGetNum: 0,
      // status: '',
      rules: rules,
      editStatus: '',
      Monthly: 0,
      formDisplay: false,
      isChecking: false,
      isShowmessage: true
    }
  },

  computed: {
    isBenefits() {
      if (this.formInline.countryId.length) {
        return this.formInline.countryId === '156'
      } else {
        return true
      }
    },
    hcPlus() {
      let a = ''
      this.selectVal.forEach((item, index) => {
        a += `HC${index + 1}` + ' + '
      })
      return a.substring(0, a.length - 2)
    },
    isDisabled() {
      return this.status === 'chat'
    }
  },
  watch: {
    'formInline.baseSalary': {
      async handler(val) {
        this.formInline.annualTotalCost = this.countGJ().toFixed(2)
        this.formInline.annualCompensation = (
          this.formInline.annualTotalCost * this.formInline.euroConv
        ).toFixed(2)
        // let that = this
        // let func = function () {
        //   that.getSocialSecurity('changeMoth')
        // }
        // throttle(func, 1000)()
      },
      deep: true
    },
    'formInline.annualTotalCost': {
      async handler(val) {
        this.formInline.annualTotalCost = this.countGJ().toFixed(2)
        this.formInline.annualCompensation = (
          this.formInline.annualTotalCost * this.formInline.euroConv
        ).toFixed(2)
        // let that = this
        // let func = function () {
        //   that.getSocialSecurity('changeMoth')
        // }
        // throttle(func, 1000)()
      },
      deep: true
    },
    ApiGetNum: {
      handler(val) {
        if (this.editStatus !== 'add') {
          if (Number(val) >= 12) {
            this.getSocialSecurity('','watch')
            this.formDisplay = true
            setTimeout(() => {
              this.loading = false
            }, 500)
          }
        } else {
          if (Number(val) >= 11) {
            this.formDisplay = true
            setTimeout(() => {
              this.loading = false
            }, 500)
          }
        }
      }
    }
  },
  props: {
    position: {
      type: String,
      default: ''
    },
    selectVal: {
      type: Array,
      default: () => []
    },
    titlePerson: {
      type: String,
      default: () => '用人申请单'
    },
    status: {
      type: String,
      default: ''
    },
    statusCode: {
      type: String,
      default: '0'
    },
    formData: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    // const data = {
    //   status: this.status
    // }
    // console.log('formDataformData', this.formData)
  },
  mounted() {
    if (this.position === 'mergeHC') {
      this.formatCharsData()
    }
    this.getData(this.formData)
  },
  methods: {
    getCEO(){
      let params = {
        assigmentProjectId: this.formInline.assigmentProjectLevel2,
        hfm_code: this.formInline.hfmCode,
        legalEntity: this.formInline.legalEntity.split('_')[1],
        workLoaction: this.formInline.workingLocationId
      }
      getSysOrgOrganizations(params).then(res=>{
        console.log(res);
        this.formInline.ceoName = res.data.ceoName
        this.formInline.ceo = res.data.ceo
      })
    },
    async checkOrganization(type = '') {
      console.log('执行的...', type, this.isChecking)
      // await new Promise((resolve, reject) => {

      // })
      if(type && type != 'month') {this.isChecking = false;}
      if(this.isChecking) return;
      this.isChecking = true;
      if(!this.formInline.assigmentProjectLevel2 ||
        !this.formInline.hfmCode || !this.formInline.legalEntity
        || !this.formInline.workingLocationId
      ) {
        this.isChecking = false;
        // resolve(true)
        return true
      } else {
        let params = {
          assigmentProjectId: this.formInline.assigmentProjectLevel2,
          hfm_code: this.formInline.hfmCode,
          legalEntity: this.formInline.legalEntity.split('_')[1],
          workLoaction: this.formInline.workingLocationId
        }
        let result = await checkOrganization(params).then(res => {
          let check = true;
          if(res && res.code == 200){
            res.data ? '' : type == 'month' ? (check = false, this.isChecking = true) : (this.$message({message:'所选机构已禁用！',type: 'error',duration:3000}),check = false, this.isChecking = true)
            setTimeout(() => {this.isChecking = false},3000)
          } else {
            check = false;
            this.isChecking = true
            setTimeout(() => {this.isChecking = false},3000)
          }
          return check;
        })
        // resolve(result)
        return result
      }
    },
    parentFunctionChange(val) {
      const data = {
        parentFunction: val
      }
      getChildFunction(data).then((ChildFunction) => {
        if (ChildFunction && ChildFunction.code === 200) {
          this.childFunctionList = ChildFunction.data
        }
      })
    },
    // show(val) {
    //   if (val) {
    //     if (val.position) {
    //       this.position = val.position
    //       this.selectVal = val.selectVal
    //
    //       this.titlePerson = '合并HC'
    //     }
    //     this.status = val.status
    //     //先弹出抽屉
    //     this.drawer = true
    //     //开启loading
    //     this.loading = true
    //
    //     this.getData(val)
    //   }
    // },
    hcClick(val) {
      if (this.position === 'mergeHC') {
        this.getFormDataApi(val)
      }
    },
    getFormDataApi(data, flag = '') {
      this.$refs.charsRef.openLoading()
      const data1 = {
        id: data.hcId
      }
      this.loading = true
      this.clearForm()
      getHcInformationByHcId(data1).then((formData) => {
        this.loading = false
        if (formData && formData.code === 200) {
          this.ApiGetNum++
          if (formData.data) {
            let formDataValue = formData.data
            Object.keys(this.formInline).forEach((item) => {
              if (formDataValue[item]) {
                this.formInline[item] = formDataValue[item]
                if (!this.formInline.minimumGuaranteedBonusCurrency) {
                  this.formInline.minimumGuaranteedBonusCurrency = 'CNY'
                }
              }
            })
            Object.keys(this.additionalInformation).forEach((item) => {
              if (formDataValue[item]) {
                this.additionalInformation[item] = formDataValue[item]
              }
            })
            // if (!this.isDisabled) {
            this.getUnitName()

            this.getHcNew()

            // }
          }
        }
      })
    },
    async assigmentProjectLevel2Change(val) {
      this.formInline.descriptionLevel3 = '';
      await this.getSocialSecurity()
      await this.getLevel3ListData();
    },
    openClient() {
      this.$refs.clientDialogRef.show(this.formInline.client)
    },
    async handleChangeRelocationCost(val) {
      if (check(val) === '[object KeyboardEvent]') {
        let value = val.target.value
        if (value) {
          this.relocationCost = value
          this.formInline.annualTotalCost = this.countGJ().toFixed(2)
        } else {
          this.formInline.annualTotalCost = 0
        }
      } else {
        this.formInline.annualTotalCost = this.countGJ().toFixed(2)
      }
    },
    returnRef() {
      if (this.hcSubmitStatus) {
        return this.$refs.personFormRef
      } else {
        this.$message.error('Number生成错误')
        return false
      }
    },
    getRef(){
    return this.$refs.personFormPersonRef
    },
    clientDialogRefConfirm(val) {
      if (val) {
        this.formInline.client = val.clientName
      }
    },
    async getSocialSecurity(val = '',type = '') {
      console.log('我要执行...',val,type)
      if (this.isDisabled) return
      if(type === ''){
        this.getCEO()
      }
      await this.checkOrganization(type).then( async (result) => {
        if(!result) return;
        if(val == 'legal') {
          this.formInline.workingLocationId = ''
        }
        if (val !== 'changeMoth') {
          this.getWorkLocation()
        }
        let objName = {
          assigmentProjectLevel2: '',
          hfmCode: '',
          legalEntity: '',
          workingLocationId: ''
        }
        let isEmpty = Object.keys(objName).every((SocialName) => {
          return (
            this.formInline[SocialName] &&
            this.formInline[SocialName].length !== 0
          )
        })
        if (isEmpty && Number(this.Monthly) && Number(this.Monthly) !== 0) {
          const data = {
            assigmentProjectLevel2: this.formInline.assigmentProjectLevel2,
            hfmCode: this.formInline.hfmCode,
            legalEntity: this.formInline.legalEntity,
            workingLocationId: this.formInline.workingLocationId,
            monthly: this.Monthly,
            baseSalary: this.formInline.baseSalary
          }
          let res = await getSocialSecurityBase(data)

          if (res && res.code === 200) {
            this.formInline.fiveInsurancesAndHousingFund = res.data || 0
            this.formInline.annualTotalCost = this.countGJ().toFixed(2)
          } else {
            this.formInline.fiveInsurancesAndHousingFund = 0
            this.formInline.annualTotalCost = this.countGJ().toFixed(2)
          }
        }
        if (this.position === 'mergeHC') {
          this.getHcNew()
        }
      })

    },
    getHcNew() {
      let hcObj = {
        assigmentProjectLevel2: '',
        hfmCode: '',
        workingLocationId: ''
      }

      let isHcObjEmpty = Object.keys(hcObj).every((SocialName) => {
        return (
          this.formInline[SocialName] &&
          this.formInline[SocialName].length !== 0
        )
      })
      if (isHcObjEmpty) {
        Object.keys(hcObj).forEach((item) => {
          hcObj[item] = this.formInline[item]
        })
        this.$refs.charsRef.openLoading()
        getNewHc(hcObj).then((res) => {
          if (res && res.code === 200) {
            this.dataSource.name = `<span>HC ID</span> <span class="hcFontText mergeTitle">${res.data.newHcType}</span>`
            this.dataSource.title = `<span class="hcFontText">${res.data.newHcId}</span> `
            this.formInline.newHcType = res.data.newHcType
            this.formInline.newHcId = res.data.newHcId
            this.formKeyHc = Math.random()
            this.hcSubmitStatus = true
            this.$refs.charsRef.closeLoading()
          } else {
            this.dataSource.name = `<span>HC ID</span> <span class="hcFontText mergeTitle">还原</span>   `
            this.dataSource.title = ``
            this.formInline.newHcType = ''
            this.formInline.newHcId = ''
            this.formKeyHc = Math.random()
            this.hcSubmitStatus = false
            this.$refs.charsRef.closeLoading()
          }
        })
      }
    },
    benefitsChange() {
      console.log(this.formInline.benefits)
      this.formInline.annualTotalCost = this.countGJ().toFixed(2)
    },
    handleChangeOtherBenefits() {
      this.formInline.annualTotalCost = this.countGJ().toFixed(2)
      this.formInline.benefits =  this.countBenefits().toFixed(2)
    },
    handleChangefiveInsurancesAndHousingFund(val) {
      if (!val) {
        this.formInline.fiveInsurancesAndHousingFund = 0
      }
      this.formInline.annualTotalCost = this.countGJ().toFixed(2)
      this.formInline.benefits =  this.countBenefits().toFixed(2)
    },
    countBenefits() {
      return (
        Number(this.formInline.otherBenefits) +
        Number(this.formInline.fiveInsurancesAndHousingFund)
      )
    },
    countGJ() {
      //    Number(this.SocialSecurity * 12) +
      return (
        Number(this.formInline.baseSalary) +
        Number(this.formInline.relocationCost) +
        Number(this.formInline.otherBenefits) +
        Number(this.formInline.fiveInsurancesAndHousingFund)
      )
    },
    async MonthlyChange(val) {
      this.Monthly = val
      this.formInline.monthly = val
      // let that = this
      // let func = function () {
      await this.getSocialSecurity('changeMoth','month')
      this.formInline.annualTotalCost = this.countGJ().toFixed(2)
      // }
      // throttle(func, 1000)()
    },
    handleChangeAnnualTotalCost(val) {},
    handleChangeEuroConv(val) {
      if (check(val) === '[object KeyboardEvent]') {
        let value = val.target.value
        if (value) {
          this.relocationCost = value
          this.formInline.annualCompensation = (
            Number(this.formInline.annualTotalCost) * Number(value)
          ).toFixed(2)
        } else {
          this.formInline.annualCompensation = 0
        }
      } else {
        this.formInline.annualCompensation = (
          Number(this.formInline.annualTotalCost) * Number(val)
        ).toFixed(2)
      }
    },
    handleChangeAnnualCompensation() {},
    getSelectList() {
      getCountry().then((Country) => {
        if (Country.code && Country.code === 200) {
          this.ApiGetNum++
          if (Country.data) {
            this.countryList = Country.data
            if (
              this.formInline.countryId &&
              !this.formInline.countryId.length
            ) {
              this.formInline.countryId = '156'
            }
          } else {
            this.countryList = []
          }
        }
      })
      getEntityHfmCode().then((HfmCode) => {
        if (HfmCode.code && HfmCode.code === 200) {
          this.ApiGetNum++
          if (HfmCode.data) {
            this.hfmCodeList = HfmCode.data
          } else {
            this.hfmCodeList = []
          }
        }
      })

      getCodeValue().then((CodeValue) => {
        if (CodeValue.code && CodeValue.code === 200) {
          this.ApiGetNum++
          if (CodeValue.data) {
            this.internalCandidateOrNewHireIdList =
              CodeValue.data.candidateOrNewHireList
            this.laborCodeIdList = CodeValue.data.labor_code
            this.type1IdList = CodeValue.data.type1
            this.workingLocationIdList = CodeValue.data.work_loaction
            this.workSchedulesIdList = CodeValue.data.work_schedules
            this.jobClassificationList = CodeValue.data.job_classification
            this.costCenterList = CodeValue.data.cost_center
            this.eventReasonNameList = CodeValue.data.event_reason
            if (
              this.formInline.eventReasonName &&
              !this.formInline.eventReasonName
            ) {
              this.formInline.eventReasonName = 'Hire(HIRNEW)'
            }
            if (this.formInline.costCenter && !this.formInline.costCenter) {
              this.formInline.costCenter = 'No Altair Cost Center (9999)'
            }
          }
        }
      })

      getEuroConv().then((EuroConv) => {
        if (EuroConv.code && EuroConv.code === 200) {
          this.ApiGetNum++
          if (EuroConv.data) {
            this.formInline.euroConv = EuroConv.data
          }
        }
      })
      getParentFunction().then((ParentFunction) => {
        if (ParentFunction && ParentFunction.code === 200) {
          this.ApiGetNum++
          this.parentFunctionList = ParentFunction.data
        }
      })

      getChildFunction().then((ChildFunction) => {
        if (ChildFunction && ChildFunction.code === 200) {
          this.ApiGetNum++
          this.childFunctionList = ChildFunction.data
        }
      })
      this.getLevel2ListData()
      this.getLevel3ListData()
      this.getLegalEntityListData()
      this.getDepartmentListData()
    },
    // 获取level2列表值
    async getLevel2ListData() {
      let parmas = {
        hfmCode: this.formInline.hfmCode
      }
      await getLevel2(parmas).then(Level2 => {
        if (Level2.code && Level2.code === 200) {
          this.ApiGetNum++
          if (Level2.data) {
            this.assigmentProjectLevel2List = Level2.data
          }
        }
      })
    },
    // 获取level3列表值
    async getLevel3ListData() {
      let params = {
        level2Id: this.formInline.assigmentProjectLevel2
      }
      await getLevel3(params).then(Level3 => {
        if (Level3.code && Level3.code === 200) {
          this.ApiGetNum++
          if (Level3.data) {
            this.descriptionLevel3List = Level3.data
          }
        }
      })
    },
    // 获取Legal Entity列表值
    async getLegalEntityListData() {
      await getLegalEntityList({
        hfmCode: this.formInline.hfmCode,
        level2Id: this.formInline.assigmentProjectLevel2,
        status: +this.statusCode,
      }).then((LegalEntity) => {
        if (LegalEntity.code && LegalEntity.code === 200) {
          this.ApiGetNum++
          if (LegalEntity.data) {
            this.legalEntityList = LegalEntity.data
          }
        }
      })
    },
    // 获取Department列表值
    async getDepartmentListData() {
      let parmas = {
        hfmCode: this.formInline.hfmCode
      }
      await getDepartmentList(parmas).then((DepartmentList) => {
        if (DepartmentList.code && DepartmentList.code === 200) {
          this.ApiGetNum++
          if (DepartmentList.data) {
            this.departmentIdList = DepartmentList.data
          }
        }
      })
    },
    getData(data) {
      this.loading = true
      this.editStatus = data.status
      // if (!this.isDisabled) {
      this.getSelectList()
      // }
      // 获取clientlist
      this.getClientList()

      this.getFormDataLine(data)

      // this.loading = false
      this.loadingStatus = true
    },
    /**
     * 获取clientlist
     */
    getClientList() {
      // /hrRafForm/getClient
      getClient1().then((res) => {
        console.log(111111, res)
        this.clientList = res.data
      })
    },
    getFormDataLine(data) {
      if (data.status !== 'add') {
        const data1 = {
          id: data.id
        }
        if (this.position === 'hcPerson') {
          data1.type = 'hc'
        }
        this.loading = true
        getFormData(data1).then((formData) => {
          this.loading = false
          if (formData && formData.code === 200) {
            this.ApiGetNum++
            if (formData.data) {
              let formDataValue = formData.data
              Object.keys(this.formInline).forEach((item) => {
                if (formDataValue[item]) {
                  this.formInline[item] = formDataValue[item]
                  if (!this.formInline.minimumGuaranteedBonusCurrency) {
                    this.formInline.minimumGuaranteedBonusCurrency = 'CNY'
                  }
                }
              })
              Object.keys(this.additionalInformation).forEach((item) => {
                if (formDataValue[item]) {
                  this.additionalInformation[item] = formDataValue[item]
                }
              })
              // if (!this.isDisabled) {
              this.getUnitName()
              // }
            }
          }
        })
      } else {
        // if (!this.isDisabled) {
        this.getUnitName()
        // }
      }
    },
    // 获取Work Location列表值
    getWorkLocation() {
      const data = {
        hfmCode: this.formInline.hfmCode,
        legalEntity: this.formInline.legalEntity,
        level2Id: this.formInline.assigmentProjectLevel2
      }
      getWorkLocationList(data).then((res) => {
        if (res && res.code === 200) {
          if (check(res.data) === typeRange.array) {
            if (res.data.length) {
              this.workingLocationIdList = res.data
            }
          }
        }
      })
    },
    async changeHfmCode() {
      this.isShowmessage = false;
      this.formInline.assigmentProjectLevel2 = '';
      this.formInline.descriptionLevel3 = '';
      this.formInline.businessUnitId = '';
      this.formInline.legalEntity = '';
      this.formInline.departmentId = '';
      this.formInline.workingLocationId = '';
      this.$refs.personFormPersonRef.clearValidate(['businessUnitId'])
      await this.getLevel2ListData();
      await this.getLevel3ListData();
      await this.getUnitName();
      await this.getLegalEntityListData();
      this.getDepartmentListData();
      await this.getSocialSecurity();
      await this.getWorkLocation();
      this.isShowmessage = true;
    },
    changeKey() {
      this.formKey = Math.random()
    },
    formatCharsData() {
      /*
             {
            name: ``,
            title: ` `
          },
       */
      let validateTotal = (rule, value, callback) => {
        if (value > Number(this.annualTotalCost)) {
          callback(new Error('超过' + this.annualTotalCost))
        } else {
          callback()
        }
      }
      if (this.position === 'mergeHC') {
        this.rules['annualTotalCost'].push({
          validator: validateTotal,
          trigger: 'change'
        })
        this.changeKey()
      }
      let ds = {
        name: ` <span>HC ID</span>   <span class="hcFontText mergeTitle">合并</span> `,
        title: ``,
        children: []
      }
      this.selectVal.forEach((item) => {
        ds.children.push({
          ...item,
          name: `<span>HC ID</span>  <span class="hcFontText targetTitle">${item.hcType}</span>`,
          title: `<span class="hcFontText">${item.hcId}</span> `
        })
      })
      const data = {
        id: this.selectVal.map((item) => item.id)
      }
      getMergeData(data).then((res) => {
        if (res && res.code === 200) {
          // ds.name = ` <span>HC ID</span> <span class="hcFontText mergeTitle">${res.data.newHcType}</span>   `
          // ds.title = ` <span>HC ID</span> <span class="hcFontText mergeTitle">${res.data.newHcType}</span>   `
          this.annualTotalCost = res.data.annualTotalCost
          this.dataSource = ds
        }
      })
    },
    async getUnitName() {
      const reData = {
        hfmCode: this.formInline.hfmCode,
        level2Id: this.formInline.assigmentProjectLevel2
      }
      this.formInline.businessUnitId = ''
      await getBusinessUnitList(reData).then((UnitNameByHfmCode) => {
        console.log('*****0',UnitNameByHfmCode)
        if (UnitNameByHfmCode.code && UnitNameByHfmCode.code === 200) {
          this.ApiGetNum++
          if (UnitNameByHfmCode.data) {
            this.businessUnitList = UnitNameByHfmCode.data;
            (this.formInline.hfmCode || this.formInline.assigmentProjectLevel2) && this.businessUnitList[0] && this.businessUnitList[0].value && (this.formInline.businessUnitId = this.businessUnitList[0].value)
          } else {
            this.businessUnitList = []
          }
        }
      })

      // getBuNameByHfmCode(reData).then((BuNameByHfmCode) => {
      //   if (BuNameByHfmCode.code && BuNameByHfmCode.code === 200) {
      //     this.ApiGetNum++
      //     if (BuNameByHfmCode.data) {
      //       this.formInline.businessUnitId = BuNameByHfmCode.data.businessUnitId
      //       this.formInline.businessUnitName =
      //         BuNameByHfmCode.data.businessUnitName
      //     }
      //   }
      // })

      // getLegalEntityList(reData).then((LegalEntity) => {
      //   if (LegalEntity.code && LegalEntity.code === 200) {
      //     this.ApiGetNum++
      //     if (LegalEntity.data) {
      //       this.legalEntityList = LegalEntity.data
      //     }
      //   }
      // })

      // getDepartmentList(reData).then((DepartmentList) => {
      //   if (DepartmentList.code && DepartmentList.code === 200) {
      //     this.ApiGetNum++
      //     if (DepartmentList.data) {
      //       this.departmentIdList = DepartmentList.data
      //     }
      //   }
      // })
    },
    submit() {
      this.$refs.personFormPersonRef.validate(async (valid) => {
        if (valid) {
          let isRight = await this.checkOrganization('true')
          console.log('返回值', isRight)
          if(isRight) {
            if(this.position === 'mergeHC'){
              if(this.hcSubmitStatus){
                this.$emit('onConfirm', JSON.parse(JSON.stringify(this.formInline)))
              }else{
                this.$message.error('Number生成错误')
              }
            }else{
              this.$emit('onConfirm', JSON.parse(JSON.stringify(this.formInline)))
            }
          }
        } else {
          return false
        }
      })
    },
    clearForm() {
      Object.keys(this.formInline).forEach((item) => {
        this.formInline[item] = resetFormInline[item]
      })
      Object.keys(this.additionalInformation).forEach((item) => {
        this.additionalInformation[item] = ' '
      })
    },
    close() {
      this.clearForm()
      this.dataSource = {}
      this.loadingStatus = false
    },
    selectPerson(val,val1 = '',val2 = '') {
      if(this.status == 'chat') return
      this.targetObj = val1
      this.toObj = val2
      if(this.toObj==='ceo'){
        this.$refs.personnelSelectionDialogRef.show({
          radio: true,
          position: 'Personnel',
          type:"CEO"
        })
      }else {
        this.$refs.personnelSelectionDialogRef.show({
          position: val,
          radio: true,
        })
      }
    },
    onPersonConfirm(val) {
      console.log('确认返回值', val)
      if(this.toObj==='ceo'){
        if (val && val.length) {
          let nameArr = []
          let IdArr = []
          let data = val
          data.map((d) => {
            nameArr.push(d.userName)
            IdArr.push(d.id)
          })
          if (IdArr.length == 1) {
            this.formInline[this.toObj] = IdArr.join(',') + ','
            this.formInline[this.targetObj] = nameArr.join(',')
          } else {
            this.formInline[this.toObj] = IdArr.join(',') + ','
            this.formInline[this.targetObj] = nameArr.join(',')
          }
        } else {
          this.formInline[this.toObj] = ''
          this.formInline[this.targetObj] = ''
        }
        return
      }

      if (this.toObj === 'ceo1' && this.targetObj == 'ceoName1') {
        this.formInline.ceo1 = val[0].lineManagerLiid;
        this.formInline.ceoName1 = val[0].englishName || val[0].userName;
        this.formInline.ceoType1 = val[0].ceoType1;
        console.log(111111, val[0], this.formInline)
        return
      }

      this.formInline.lineManagerLiid = val[0].lineManagerLiid;
      this.formInline.lineManagerName = val[0].englishName || val[0].userName;
      this.formInline.lineManagerEmail = val[0].email;
      val[0].lineManagerBaseId !== undefined ? (this.formInline.lineManagerBaseId = val[0].lineManagerBaseId) : ''
      val[0].lineManagerPerformanceBaseId !== undefined ? (this.formInline.lineManagerPerformanceBaseId = val[0].lineManagerPerformanceBaseId) : ''
    }
  }
}
</script>
<style>
.flexFormItem .el-form-item__content {
  display: flex !important;
}
</style>
<style lang="scss" scoped>
.itemEllipsis {
  width: 100%;
  overflow: hidden; //超出隐藏
  white-space: nowrap; //不折行
  text-overflow: ellipsis; //溢出显示省略号
  color: #868686;
}
.Freelance {
  //margin-top: 30px;
  display: flex;
  font-size: 14px;
  transform: scale(110%);
  margin-left: 25px;
  margin-bottom: 10px;
}
.Billable {
  margin: 0px 0 30px 0;
}
.Relocation {
  .el-input-number {
    width: 83%;
  }
}
.RelocationCost {
  .el-input-number {
    width: 48%;
  }
}

.flexFormItem {
  display: flex;
  align-items: center;

  .yearly {
    height: 100%;
    margin-left: 10px;
    span {
      display: inline-block;
    }
  }
}
.PersonnelForm {
  min-height: 100px;
}
.PersonnelDialogFrom-inline {
  .title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #303133;
    margin-bottom: 12px;
    margin-top: 8px;
  }
  .el-form-item {
    margin-bottom: 32px !important;
  }
}

//.LTIM {
//  margin-top: 40px;
//  margin-left: 40px;
//  transform: scale(110%);
//}

.typeSelect {
  .el-select-dropdown__item {
    //height: 60px;
    height: auto;
    line-height: 1.5;
    width: 300px;
    overflow: auto;
    white-space: normal;
    padding: 15px;
  }
  .selectLabel {
    font-weight: bold;
  }
  .selectMsg {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
  }
  .selectMsg,
  .selectLabel {
    display: block;
  }
}
.hide-error-border {
  :deep(.el-form-item.is-error) {
    border-color: #DCDFE6;
  }
  :deep(.el-form-item.is-error .el-input__inner) {
    border-color: #DCDFE6;
  }
}
</style>
<style lang="scss">
.mergeHcSlot {
  margin-bottom: 40px;
  &__content {
    display: flex;
    &__one {
      width: 300px;
      border-right: 1px solid #ebeef5;
      padding-right: 37px;
    }

    .el-statistic {
      border: 0 !important;
      .con {
        justify-content: flex-start;
        .number {
          font-size: 24px;
          font-family: Oswald-Regular_Bold;
          font-weight: normal;
          color: #323435;
          padding: 0;
        }
      }
      margin-bottom: 8px;
    }
    .hcPlus {
      div {
        border: 0 !important;
      }
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #323435;
    }
  }
}
.thirteenSalary {
  display: flex !important;
}
.thirteenSalary .el-form-item {
  margin-top: 32px !important;
}
.thirteenSalary .el-switch {
  margin-left: 10px;
}
</style>
