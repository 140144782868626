export const TableColumn = [
  // {
  //   key: 'hcId',
  //   name: 'HC ID',
  //   dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
  //   visible: true, // 是否展示在表格列中
  //   sortable: false, // 是否可以排序
  //   fixed: false, // 固定列的位置(left, right)
  //   width: 61 // 默认宽度，像素
  // },
  {
    key: 'jobTitle',
    name: 'Job Title',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: false, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 150
  },
  {
    key: 'assigmentProjectLevel2',
    name: 'Level2',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: null
  },
  {
    key: 'descriptionLevel3',
    name: 'Level3',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 150
  },
  {
    key: 'annualTotalCost',
    name: 'Annual Total Cost (Local Currency)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    align: 'right',
    width: 135
  },

  {
    key: 'hrbpOwner',
    name: 'HRBP Owner',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 110
  },
  {
    key: 'hcStatus',
    name: 'HC Status',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  }
]
export const TableColumn1 = [
  // {
  //   key: 'currentApprovalComments',
  //   name: 'Groupe CFO驳回理由',
  //   dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
  //   visible: true, // 是否展示在表格列中
  //   sortable: false, // 是否可以排序
  //   fixed: false, // 固定列的位置(left, right)
  //   width: 110 // 默认宽度，像素
  // },
  {
    key: 'hcNature',
    name: 'HC Nature',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'hcSubNature',
    name: 'HC SubNature',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 115
  }
]
export const submitTableColumn = [
  {
    key: 'hcNature',
    name: 'HC Nature',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: null
  },
  {
    key: 'hcSubNature',
    name: 'HC SubNature',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: null
  }
]
