<template>
  <div
    class="OrganizationChart"
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
  >
    <VoBasic
      :data="dataSource"
      :direction="'b2t'"
      :nodeContent="'title'"
      :pan="true"
      :createNode="position === 'bloodMap' ? null : createNode"
    />
  </div>
</template>

<script>
import { VoBasic } from 'vue-orgchart'
import { getItem, removeItem, setItem } from '@/utils/storage'
export default {
  name: 'charts',
  components: {
    VoBasic
  },
  props: {
    dataSource: {
      type: Object,
      default: () => {}
    },
    position: {
      type: String,
      default: ''
    }
  },

  methods: {
    openLoading() {
      this.loading = true
    },
    closeLoading() {
      this.$nextTick(() => {
        this.loading = false
      })
    },
    add(data) {
      setItem('yc__hcClick', data)
      this.$emit('hcClick', data)
    },
    createNode(node, data) {
      let childElementCount = node.childElementCount
      let secondMenuText = document.createElement('span'),
        secondMenuButton = document.createElement('input'),
        secondMenuDiv = document.createElement('div')
      secondMenuDiv.setAttribute(
        'style',
        'transform:rotate(-180deg);  text-align: left; position: relative;top:-11px'
      )
      secondMenuButton.setAttribute('type', 'radio')
      secondMenuButton.setAttribute('name', 'hcInput')
      secondMenuButton.setAttribute('id', 'inputColor')
      secondMenuButton.setAttribute(
        'style',
        'width:16px;height:16px;border-radius: 50%;'
      )
      secondMenuText.innerHTML = '填充该HC信息'
      let yc__hcClick = getItem('yc__hcClick')
      if (yc__hcClick) {
        if (yc__hcClick.hcId === data.hcId) {
          secondMenuButton.setAttribute('checked', 'true')
        }
      }
      let that = this
      secondMenuButton.addEventListener('click', function () {
        that.add(data)
      })
      let content = node.getElementsByClassName('title')
      secondMenuDiv.appendChild(secondMenuButton)
      secondMenuDiv.appendChild(secondMenuText)
      if (childElementCount > 3) {
        node.insertBefore(secondMenuDiv, content[0])
      }
    }
  },
  data() {
    return {
      loading: false
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin node {
  .node {
    width: 340px;
    padding: 23px 20px 18px 0 !important;
    box-sizing: border-box;
    background: #f4f4f4;
    border-radius: 8px;
    margin-right: 12px;
    .hcFontText {
      font-size: 14px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #323435;
    }
    .mergeTitle {
      width: 24px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      background: #2490e3;
      border-radius: 2px;
      padding: 1px 3px 1px 3px;
      color: #ffffff;
    }
    .targetTitle {
      width: 24px;
      height: 17px;
      padding: 1px 3px 1px 3px;
      font-size: 12px;
      background: #6ebaaf;
      border-radius: 2px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
    .title {
      text-align: left;
      background: none;
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #868686;
      margin-bottom: -5px;
    }
    .content {
      text-align: left;
      border: none;
      background: none;
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #868686;
    }
  }
}
.OrganizationChart {
  width: 60vw;
  overflow: hidden;
  #chart-container {
    //margin-top: -30px;
    :deep(.orgchart) {
      @include node;
    }
    :deep(.lines) {
      .downLine {
        width: 1px !important;
        background: #ebeef5;
        position: relative;
        left: 9px;
        &:before {
          position: relative;
          top: -18px;
          right: 5px;
          content: '';
          width: 0;
          height: 0;
          border-right: 6px solid transparent;
          border-bottom: 6px solid #bb9667;
          border-left: 6px solid transparent;
        }
      }
      .topLine {
        border-top: 1px solid #ebeef5;
      }
      .rightLine {
        border-right: 1px solid #ebeef5;
      }
      .leftLine {
        border-left: 0;
      }
    }
    :deep(.nodes) {
      @include node;
    }
  }
}
</style>
<style lang="scss">
#inputColor {
  margin-right: 8px;
  position: relative;
  cursor: pointer;
  top: 3px;
}
.orgchart {
  //width: 70% !important;
}
.orgchart > table:first-child {
  //margin: auto 35%;
  position: relative;
  left: 0.5vw;
}
</style>
