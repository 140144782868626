import { saveSelectUser } from '@/api/quto'
import PersonnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog.vue'
import { getItem, setItem } from '@/utils/storage'

export default {
  components: {
    PersonnelSelectionDialog
  },
  methods: {
    isSelect() {
      console.log(this.selectVal)
      if (this.selectVal.length) {
        return true
      } else {
        this.$message.warning('请选择')
        return false
      }
    },
    deleteRole(row) {
      return row.unapprovedHcStatus === '待提交'
    },
    editRole(row) {
      if (row) {
        // let arr = ['待HRBP提交', '待HRBP Leader提交', '待Brand CFO提交审批']
        let isUnapproval = row.unapprovedHcStatus === '待提交' || row.messageType === '1'
        // let isAproval = arr.every(
        //   (item) =>
        //     row.approvalStatusList && row.approvalStatusList.includes(item)
        // )
        // return isUnapproval && isAproval
        return isUnapproval
      } else {
        return false
      }
    },
    submitRole(roleArr = []) {
      let msgList = roleArr.filter(item=>item.messageType === '1')
      if(msgList.length > 0){
        return true
      }
      let UnapprovedHCStatusArr = ['待提交', '审批中']
      let approvalStatusList = roleArr.every(
        (item) =>
          item.approvalStatusList &&
          item.approvalStatusList.every(
            (item1) => item1 && item1.includes('待')
          )
      )
      let UnapprovedHCStatus = roleArr.every((item) =>
        UnapprovedHCStatusArr.includes(item.unapprovedHcStatus)
      )
      let userName = getItem('userRole')
      let leaderRole = () => {
        if (userName && userName === 'HRBP Leader') {
          return roleArr.some(
            (item) =>
              item.approvalStatusList &&
              item.approvalStatusList.some(
                (item1) => item1 && item1.includes('HRBP Leader已提交')
              )
          )
        }
        if (userName && userName === 'HRBP') {
          return roleArr.some(
            (item) =>
              item.approvalStatusList &&
              item.approvalStatusList.some(
                (item1) => item1 && item1.includes('HRBP已提交')
              )
          )
        }
        if (userName && userName.toLocaleLowerCase().includes('cfo')) {
          return roleArr.some(
            (item) =>
              item.approvalStatusList &&
              item.approvalStatusList.some(
                (item1) => item1 && item1.includes('BRAND CFO已提交审批')
              )
          )
        }
      }
      let leaderRoleUnSubmit = () => {
        if (userName && userName === 'HRBP Leader') {
          return roleArr.some(
            (item) =>
              item.approvalStatusList &&
              item.approvalStatusList.some(
                (item1) => item1 && item1.includes('待HRBP Leader提交')
              )
          )
        }
        return true
      }
      if (roleArr.length) {
        return (
          ((leaderRole() && approvalStatusList) || leaderRoleUnSubmit()) &&
          UnapprovedHCStatus
        )
      } else {
        return false
      }
    },
    distribution() {
      if (this.isSelect()) {
        // setItem('personSelectVal__yc', this.selectVal)
        this.$refs.PersonnelSelectionDialogRef.show({
          position: 'Personnel'
        })
      }
    },
    personalOnConfirm(val) {
      const data = {
        userId: val.map((item) => item.id).toString(),
        id: this.selectVal.map((item) => item.id).toString(),
        type: 'employmentapplication'
      }
      saveSelectUser(data).then((res) => {
        if (res && res.code === 200) {
          this.$emit('close')
          this.selectVal = []
          this.multipleSelection = []
          this.$message.success('分配成功')
        }
      })
    },
    cancelRole(roleArr) {
      let UnapprovedHCStatusArr = ['审批中']
      let approvalStatusList = roleArr.every(
        (item) =>
          item.approvalStatusList &&
          item.approvalStatusList.includes('待Brand CFO提交审批')
      )
      let UnapprovedHCStatus = roleArr.every((item) =>
        UnapprovedHCStatusArr.includes(item.unapprovedHcStatus)
      )
      if (roleArr.length) {
        return approvalStatusList && UnapprovedHCStatus
      } else {
        return false
      }
    }
  }
}
