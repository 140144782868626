<template>
  <el-dialog
    title="提交"
    :visible.sync="dialogVisible"
    width="40%"
    destroy-on-close
    @close="close"
  >
    <div class="flexBet">
      <div>留言：</div>
      <div class="flex-1">
        <el-input
          type="textarea"
          placeholder="留言非必填，点击提交后将直接提交给Groupe CFO"
          v-model="textarea"
          :autosize="{ minRows: 6}"
          maxlength="1000"
          show-word-limit
        />
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="close">取 消</el-button>
    <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
export default {
  name: "leaveMessageDialog",
  data(){
    return{
      dialogVisible:false,
      textarea:'',
      loading:false,
    }
  },
  methods:{
    close(){
      this.textarea = ''
      this.dialogVisible = false
    },
    submit(){
      this.loading = true
      this.$emit('submit',this.textarea)
      this.close()
    },
    show(){
      this.loading = false
      this.dialogVisible = true
    }
  }
};
</script>

<style type="sass" scoped>
  ::v-deep .el-dialog .el-dialog__body{
    padding: 20px ;
  }
</style>
