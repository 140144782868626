export const resetFormInline = {
  countryId: '',
  hfmCode: '',
  bussinessUnitName: '',
  internalBrandNamelevel: '',
  lineManagerLiid: '',
  lineManagerName: '',
  client: '',
  laborCodeId: '',
  departmentId: '',
  jobTitle: '',
  workingLocationId: '',
  estimatedStartDate: '',
  newCandidateName: '',
  internalCandidateOrNewHireId: '',
  openLessThan90daysOrFreelanceUtillized: false,
  baseSalary: '',
  relocationCost: 0,
  annualTotalCost: 0,
  euroConv: 0,
  annualCompensation: 0,
  bill100Percent: false,
  budgetWithinLastRf: false,
  inFinalRecruitmentStage: false,
  type1Id: '',
  ifBackfillNameOfDepartingEmployee: '',
  ifBackfillDateOfDeparture: '',
  ifBackfillWhereDidEmployeeGo: '',
  ifBackfillDepartingEmployeeAnnualTotalCost: '',
  ifBackfillDepartingEmplyeeAnnualBaseSalary: '',
  workSchedulesId: '',
  benefits: 0,
  eventReasonName: '',
  costCenter: '',
  jobClassification: '',
  level2: '',
  level3: '',
  businessCaseToJustifyTheRecruitment: '',
  thirteenSalary: false
}
