<template>
  <div class="submittedTable personTable">
    <div class="buttonTable">
      <el-button @click="distribution">分配</el-button>
    </div>
    <PocTable
      ref="tTableRef"
      v-loading="loading"
      :data="tableData"
      stripe
      :current-page.sync="pageConfig.pageNum"
      :total="pageConfig.total"
      size="large"
      :max-height="100000000"
      @select="handleSelectionChange"
      @size-change="pageSizeChange"
      @page-current-change="pageChange"
      @select-all="selectAll"
      highlight-current-row
    >
      <el-table-column type="selection" fixed width="50" />
      <el-table-column
        label="HC ID"
        key="hcId"
        prop="hcId"
        show-overflow-tooltip
        width="230"
      >
        <template slot-scope="scope">
          <span class="textHover" @click="onChat(scope.row)">{{
            scope.row.hcId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="item in columnConfig"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :width="item.key === 'no' ? 60 : null"
        :min-width="item.width"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        v-for="item in submitTableColumn"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :min-width="item.width"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="Operation"
        width="130"
        fixed="right"
        align="center"
      >
        <template slot-scope="scope">
          <svg-icon
            role="copy"
            v-permission
            icon="tableCopy"
            class="tablePointSvgClass"
            title="复制"
            @click.native="copy(scope.row)"
          />
        </template>
      </el-table-column>

      <PersonnelSelectionDialog
        ref="PersonnelSelectionDialogRef"
        @onConfirm="personalOnConfirm"
      />
    </PocTable>
    <PersonnelDialogFrom ref="PersonnelDialogFromRef" @onConfirm="onConfirm" />
  </div>
</template>

<script>
import { TableColumn } from '@/views/personnelRequisition/TableColumn'
import ApprovalStatusDisplay from '@/views/personnelRequisition/components/ApprovalStatusDisplay.vue'
import PersonnelDialogFrom from '@/views/personnelRequisition/components/PersonnelDialogFrom.vue'
import { submitTableColumn } from '@/views/personnelRequisition/TableColumn'
import mixins from '@/views/personnelRequisition/mixins'
import {
  changeSubmissionStatus,
  checkEdit,
  commited,
  deleteForm,
  hrRafFormList,
  savePerson
} from '@/api/personnelRequisition'

export default {
  mixins: [$PCommon.TableMixin, mixins],
  name: 'submittedTable',
  inject: ['parent'],
  components: {
    ApprovalStatusDisplay,
    PersonnelDialogFrom
  },
  data() {
    return {
      columnConfig: TableColumn,
      submitTableColumn,
      tableData: [],
      selectVal: []
    }
  },
  methods: {
    onEdit(val) {
      const data = {
        id: val.id
      }
      checkEdit(data).then((res) => {
        if (res && res.code === 200) {
          this.formStatus = 'edit'
          this.id = val.id
          this.$refs.PersonnelDialogFromRef.show({
            status: this.formStatus,
            id: this.id
          })
        } else {
          this.$message.warning(res.data)
        }
      })
    },
    deleteP(item) {
      if (this.isSelect()) {
        const data = {
          ids: item.id.toString()
        }
        deleteForm(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('删除成功')
            this.queryApi()
          }
        })
      }
    },
    onConfirm(val) {
      if (this.formStatus !== 'add' && this.formStatus !== 'copy') {
        const data = {
          id: this.id,
          ...val
        }
        savePerson(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('修改成功')
            this.queryApi()
            this.$refs.PersonnelDialogFromRef.close()
          }
        })
      } else {
        const data = {
          id: '',
          ...val
        }
        savePerson(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('新增成功')
            this.queryApi()
            this.$refs.PersonnelDialogFromRef.close()
          }
        })
      }
    },
    selectAll(rows) {
      this.multipleSelection = [];
      this.selectVal = [];
      if (rows.length) {
        rows.forEach((row) => {
          if (!this.multipleSelection.find((item) => item === row.id)) {
            this.multipleSelection.push(row.id);
            this.selectVal.push(row)
          }
        });
      } else {
        this.tableData.forEach((row) => {
          this.multipleSelection = this.multipleSelection.filter(
            (item) => item !== row.id
          );
          this.selectVal = this.selectVal.filter(
            (item) => item.id !== row.id
          );
        });
      }
    },
    copy(val) {
      this.formStatus = 'copy'
      this.id = val.id
      this.selectVal = [];
      this.multipleSelection = [];
      this.$refs.PersonnelDialogFromRef.show({
        status: this.formStatus,
        id: this.id
      })
    },
    handleSelectionChange(val, row) {
      // this.selectVal = val
      if (this.multipleSelection.find((item) => item === row.id)) {
        //下面这个filter方法就是删除的方法
        this.multipleSelection = this.multipleSelection.filter(
          (item) => item !== row.id
        );
        this.selectVal = this.selectVal.filter(
          (item) => item.id !== row.id
        );
      } else {
        this.multipleSelection.push(row.id);
        this.selectVal.push(row);
      }
    },
    onChat(val) {
      this.formStatus = 'chat'
      this.id = val.id
      this.$refs.PersonnelDialogFromRef.show({
        status: this.formStatus,
        id: this.id
      })
    },
    queryApi(val) {
      const data = {
        ...this.parent.formInline,
        unapprovedHcStatus: this.parent.dropdownValue,
        pageNum: this.pageConfig.pageNum,
        pageSize: this.pageConfig.pageSize
      }
      this.loading = true
      hrRafFormList(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          if (res.data && res.data.list) {
            this.tableData = res.data.list
            this.pageConfig.total = res.data.total
            // this.highlightedTableData()
          } else {
            this.tableData = []
            this.pageConfig.total = 0
          }
        }
      })
    },
    highlightedTableData() {
      let selectVal = this.multipleSelection
      if (selectVal) {
        this.tableData.forEach((item) => {
          let a = selectVal.find((item1) => item.id === item1)
          if (a) {
            this.$nextTick(() => {
              this.$refs.tTableRef.toggleRowSelection(item, true)
            })
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.deletedTable {
  .textHover {
    &:hover {
      cursor: pointer;
      color: $--color-button-primary;
      text-decoration: underline;
    }
  }
}
.personTable {
  .buttonTable {
    left: 105px !important;
  }
}
</style>
