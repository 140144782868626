<template xmlns="">
  <div class="PersonnelDialogFrom">
    <el-drawer
      :visible.sync="drawer"
      size="95%"
      direction="btt"
      :before-close="close"
      destroy-on-close
      :show-close="false"
    >
      <template v-slot:title>
        <div>{{ titlePerson }}</div>
        <div>
          <el-button @click="close">取消</el-button>
          <el-button
            v-if="!isDisabled && status !== 'details'"
            @click="submit"
            type="primary"
            >保存</el-button
          >
        </div>
      </template>
      <div
        class="drawer_content"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <PersonnelForm
          :select-val="selectVal"
          :form-data="formData"
          :position="position"
          :status="status"
          :statusCode="statusCode"
          ref="personFormRef1"
          @onConfirm="onConform"
          :title-person="titlePerson"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import PersonnelForm from '@/views/personnelRequisition/components/PersonnelForm.vue'
import { removeItem } from '@/utils/storage'
export default {
  name: 'PersonnelDialogFrom',
  components: {
    PersonnelForm
  },
  props: {
    statusCode: {
      type: String,
      default: '0'
    },
  },
  data() {
    return {
      selectVal: [],
      formData: {},
      loading: false,
      position: '',
      drawer: false,
      status: '',
      titlePerson: '用人申请单'
    }
  },
  computed: {
    isDisabled() {
      return this.status === 'chat'
    }
  },
  methods: {
    show(val) {
      if (val) {
        if (val.position) {
          this.position = val.position
          this.selectVal = val.selectVal
          // this.formatCharsData()
          this.titlePerson = '合并HC'
        }
        this.status = val.status
        this.formData = val
        //先弹出抽屉
        this.drawer = true
        //开启loading
        // this.loading = true

        // this.getData(val)
      }
    },
    submit() {
      this.$refs.personFormRef1.submit()
    },
    onConform(val){
      this.$emit(
        'onConfirm',
        val
      )
    },
    clearForm() {
      this.$refs.personFormRef1.close()
    },
    close() {
      removeItem('yc__hcClick')
      this.loadingStatus = false
      this.drawer = false
      this.clearForm()
      this.dataSource = {}
    }
  }
}
</script>

<style lang="scss">
.PersonnelDialogFrom {
  .el-drawer__wrapper {
    .el-drawer__header {
      margin-bottom: 16px;
      div {
        &:first-child {
          display: inline-block;
          padding-left: 19.2%;
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #303133;
        }
      }
    }
    .el-drawer__body {
      .drawer_content {
        width: 100%;
        height: 100%;
        padding: 0 20% 50px 20%;
      }
    }
  }
}
</style>
