<template>
  <div class="ApprovalStatusDisplay">
    <div class="ApprovalStatusDisplay__status">
      <div></div>
      <div>{{ unapprovedHcStatus }}</div>
    </div>
    <el-popover
      placement="bottom"
      class="ApprovalStatusDisplay__popover"
      width="197"
      trigger="click"
    >
      <div class="ApprovalStatusDisplay__popover__content">
        <div
          class="ApprovalStatusDisplay__popover__content__item"
          v-for="item in approvalStatus"
        >
          <span style="margin-right: 4px">{{ item.approvalStatusName }}</span>
          <span>
            <svg-icon
              v-if="isIcon(item.approvalStatus)"
              icon="trueIcon"
              class="tablePointSvgClass"
            />
            <svg-icon v-else icon="falseIcon" class="tablePointSvgClass" />
          </span>
        </div>
      </div>
      <el-button slot="reference">...</el-button>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'ApprovalStatusDisplay',
  props: {
    approvalStatus: {
      require: true,
      default: () => []
    },
    unapprovedHcStatus: {
      require: true,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {
    isIcon(val) {
      if (val) {
        return val===1
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ApprovalStatusDisplay {
  display: flex;
  width: 100%;
  height: 100%;
  &__status {
    display: flex;
    align-items: baseline;
    div {
      &:first-child {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #e6a23c;
        margin-right: 4px;
      }
      &:last-child {
        font-size: 12px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #4e4e50;
      }
    }
  }
  &__popover {
    width: 24px;
    height: 24px;
    margin-left: 12px;
    overflow: hidden;
    :deep(.el-popover__reference-wrapper) {
      width: 24px;
      height: 24px;
      .el-button {
        width: 24px;
        height: 24px;
        padding: 0;
        span {
          position: relative;
          top: -3px;
          font-weight: bold;
          letter-spacing: 1px;
        }
      }
    }
    &__content {
      text-align: right;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1b1d1e;
      &__item {
        margin-bottom: 6px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
