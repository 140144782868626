<template>
  <div class="BaseSalary">
    <el-input-number
      class="describe"
      v-model="num"
      :step="1"
      :disabled="position==='hcPerson'"
      @change="handleChangeNum"
      label="描述文字"
    ></el-input-number>
    <!--       @keyup.native="handleChangeNum($event)"-->
    <span>Yearly=</span>
    <el-input
      v-model="year"
      class="year"
      disabled
      controls-position="right"
    ></el-input>
    <span>X</span>
    <el-input-number
      v-model="Monthly"
      :step="1"
      :disabled="position==='hcPerson'"
      @change="handleChangeMonthly"
      label="描述文字"
    ></el-input-number>
    <span>Monthly</span>
    <!--      @keyup.native="handleChangeMonthly($event)"-->
  </div>
</template>

<script>
import { check, typeRange } from '@/utils/util'

export default {
  name: 'BaseSalary',
  props: ['value', 'thirteenSalary','position'],
  data() {
    return {
      num: this.value,
      year: 12,
      Monthly: 0
    }
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.num = val
          this.handleChangeYear()
        }
      },
      deep: true
    },
    num: {
      handler(val) {
        this.$emit('input', val)
      },
      deep: true,
      immediate: true
    },
    thirteenSalary: {
      handler(val) {
        if (val) {
          this.year = 13
          this.handleChangeYear()
        } else {
          this.year = 12
          this.handleChangeYear()
        }
      },
      deep: true,
      immediate: true
    },
    Monthly: {
      handler(val) {
        if (val && Number(val) !== 0) {
          this.$emit('MonthlyChange', val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleChangeNum(val) {
      if (check(val) === '[object KeyboardEvent]') {
        let value = val.target.value
        if (value) {
          this.num = Number(value)
          this.Monthly = (this.num / this.year).toFixed(2)
        } else {
          this.Monthly = 0
        }
      } else {
        this.Monthly = (this.num / this.year).toFixed(2)
      }
    },
    handleChangeYear() {
      this.Monthly = (this.num / this.year).toFixed(2)
    },
    handleChangeMonthly(val) {
      if (check(val) === '[object KeyboardEvent]') {
        let value = val.target.value
        if (value) {
          this.Monthly = Number(value)
          this.num = (this.year * this.Monthly).toFixed(2)
        } else {
          this.num = 0
        }
      } else {
        this.num = (this.year * this.Monthly).toFixed(2)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.BaseSalary {
  :deep(.el-input-number) {
    width: 200px !important;
    margin-right: 10px;
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
  }
  .describe{
    width:23.8% !important;
    margin-right: 20px !important;
  }
  .year {
    width: 160px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .el-input {
    width: 50px;
    margin-right: 20px;
    margin-left: 20px;
  }
}
</style>
